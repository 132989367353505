<template>
  <div>
    <div>
      <iframe :src="`https://ftxpush.web.app/cvtas/chirps.html?min=1&color=RdYlGn&rev=1&lay=${lay}${url ? '&url='+url : ''}${scalemax ? '&scalemax='+scalemax : ''}${scalemin ? '&scalemin='+scalemin : ''}${title ? '&title='+title : ''}`" 
        style="position: relative; height: 91vh; width: 100%;" scrolling="no" frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
     lay: "",
     url: null,
     color: null,
     scalemax: null,
     scalemin: null,
     title: null,
    };
  },
  components: {
    // KTCodePreview
  },
  beforeMount(){
    // this.lay = this.route.params
    // console.log('ruta:',this.$route.query.lay);
    this.lay = this.$route.query.lay;
    this.url = this.$route.query.url;
    this.color = this.$route.query.color;
    this.scalemax = this.$route.query.scalemax;
    this.scalemin = this.$route.query.scalemin;
    this.title = this.$route.query.title;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Monitoreo", route: "alert" },
      { title: "Monitoreo" }
    ]);
  },
  watch:{
    $route (to){
        this.lay = to.query.lay;
        this.url = to.query.url;
        // console.log('route change: ',to,' ',from)
    }
  } 
};
</script>
